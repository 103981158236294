import "./DocumentationPage.css";

import { ArrowDownTree, ArrowUpTree, DeleteTree, EditTree, PlusSVG } from "../../../../lib/assets/icons";
import { ItemDefinition, Tree } from "../../../../lib/components/tree/Tree";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEditor, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AddPagePopup } from "./AddPagePopup";
import { AreaContainer } from "../../../../lib/layouts/containers/AreaContainer";
import { EditPagePopup } from "./EditPagePopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { GetPagesResponse } from "../../models/services/GetPagesResponse";
import { HorizontalResizer } from '../../../../lib/layouts/containers/horizontal-resizer/HorizontalResizer';
import { Loader } from "../../../../lib/components/loader/Loader";
import { PageChangedEvent } from "../../../../common/events/PagesChangedEvent";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PageContent } from "./PageContent";
import { PageService } from "../../services/PageService";
import { PageTreeItem } from '../../models/PageTreeItem';
import { Page as ResponsePage } from "../../models/services/GetPagesResponse";
import { ScrollLayout } from '../../../../lib/layouts/containers/scroll-layout/ScrollLayout';
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useOnInit } from "../../../../lib/infrastructure/ui/useOnInit";
import { useServiceCallPro } from "../../../../common/services/UseServiceCall";

var pageSvc = new PageService();

function pageRoute(spaceId: string, pageId: string) {
  return `/spaces/${spaceId}/pages/${pageId}`;
}

function removePageTreeItemById(
  tree: PageTreeItem[],
  pageId: number
): number | null {

  const rootIndex = tree.findIndex(node => node.pageId === pageId);
  if (rootIndex !== -1) {
    tree.splice(rootIndex, 1); // Remove the item from the root array
    return null; // Root-level items don't have a parent, return null
  }

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    // If the target node is found, remove it and return the parent ID
    if (node.children) {
      const childIndex = node.children.findIndex(child => child.pageId === pageId);

      if (childIndex !== -1) {
        node.children.splice(childIndex, 1);
        return node.pageId; // Return the parent's ID
      }

      // Recursively search in the children
      const result = removePageTreeItemById(node.children, pageId);
      if (result !== null) {
        return result;
      }
    }
  }

  return null; // Return null if not found
}

const mapGetPagesResponseToPages = (response: GetPagesResponse): PageTreeItem[] => {

  const mapToPageTreeItem = (page: ResponsePage): PageTreeItem => ({
    title: page.title,
    spaceId: page.spaceId,
    pageId: page.pageId,
    dateModified: page.dateModified,
    dateCreated: page.dateCreated,
    children: page.children?.map(mapToPageTreeItem)
  });

  return response.pages.map(mapToPageTreeItem);
}



export function DocumentationPage() {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const openEditor = useEditor();
  const { spaceId, pageId } = useParams();

  const [pages, setPages] = useState<PageTreeItem[]>([]);

  const getPagesCall = useServiceCallPro(pageSvc.getPages);
  const movePageCall = useServiceCallPro(pageSvc.movePage);
  const deletePageCall = useServiceCallPro(pageSvc.deletePage);

  const leftAreaRef = useRef<HTMLDivElement>(null);
  const rightAreaRef = useRef<HTMLDivElement>(null);

  /****************************
  * DATA REQUESTS
  *****************************/
  const loadPages = useCallback(() => {
    if (!spaceId) return;

    getPagesCall.invoke(spaceId)
      .then(response => {
        setPages(mapGetPagesResponseToPages(response));
      });

  }, [setPages, spaceId, getPagesCall.invoke]);



  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useOnInit(() => {
    loadPages();
  });

  const defaultPageId = useMemo(() => {
    return pages.length > 0 ? pages[0].pageId : undefined
  }, [pages]);

  const currentPageId = useMemo(() => {
    return pageId && parseInt(pageId);
  }, [pageId]);
  /****************************
   * USER ACTIONS
   *****************************/

  const handleAddedPageCompleted = useCallback((newPageId:number)=>{
    loadPages();
    spaceId && navigate(pageRoute(spaceId, `${newPageId}`));
  },[spaceId])

  const handleMovePage = useCallback(
    (space: number, page: number, instructionValue: number) => {
      movePageCall
        .invoke(space, page, { instruction: instructionValue })
        .then(() => {
          loadPages();
        });
    },
    [loadPages, movePageCall.invoke]
  );

  const handleDeleteOnConfirmClicked = useCallback(
    (space: number, page: number) => {
      deletePageCall
        .invoke(space, page)
        .then(() => {
          var pagesCopy = [...pages];
          var parentPage = removePageTreeItemById(pagesCopy, page);
          setPages(pagesCopy);

          spaceId && navigate(pageRoute(spaceId, `${parentPage || pagesCopy[0].pageId}`));

          openPopup(<SuccessPopup>Page deleted successfully!</SuccessPopup>);
          PageChangedEvent.firePageChanged();
        })
        .catch((error) => {
          if (error.response.data) {
            openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
          } else {
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
          }
        });
    }, [openPopup, deletePageCall.invoke, spaceId, pages]);

  const handleDeleteClicked = useCallback((v: PageTreeItem) => {
    openPopup(
      <WarningPopup onConfirmClicked={() => handleDeleteOnConfirmClicked(v.spaceId, v.pageId)}>
        Are you sure you want to delete this page?
      </WarningPopup>
    )
  }, [handleDeleteOnConfirmClicked, openPopup]);


  const handleAddClicked = useCallback((v?: PageTreeItem) => {
    if (!spaceId) return
    openPopup(
      <AddPagePopup
        onCompleted={handleAddedPageCompleted}
        spaceId={spaceId}
        pageId={v?.pageId.toString()}
      />
    )
  }, [handleAddedPageCompleted, openPopup, spaceId]);

  const handleEditClicked = useCallback((v: PageTreeItem) => {
    if (!spaceId) return

    openEditor(<EditPagePopup pageId={v.pageId.toString()} spaceId={spaceId} onSave={loadPages} />);
  }, [openPopup, spaceId]);

  const handlePageClicked = useCallback((v: PageTreeItem) => {
    if (!v.pageId || !spaceId) return;
    navigate(pageRoute(spaceId, `${v.pageId}`));
  }, [spaceId, navigate]);



  /****************************
   * CSS & HTML
   *****************************/

  const itemDef = useMemo<ItemDefinition<PageTreeItem>>(
    () => ({
      render: (v) => v.title,
      children: (v) => v.children,
      actions: (v) => [
        {
          label: "Add",
          icon: <PlusSVG />,
          onClick: handleAddClicked
        },
        {
          label: "Move Up",
          icon: <ArrowUpTree />,
          onClick: () => handleMovePage(v.spaceId, v.pageId, 1),
        },
        {
          label: "Move Down",
          icon: <ArrowDownTree />,
          onClick: () => handleMovePage(v.spaceId, v.pageId, 0),
        },
        {
          label: "Edit",
          icon: <EditTree />,
          onClick: handleEditClicked
        },
        {
          label: "Delete",
          icon: <DeleteTree />,
          onClick: handleDeleteClicked
        },
      ],
      onItemClicked: handlePageClicked,
    }), [handleMovePage, handleAddClicked, handleEditClicked, handleDeleteClicked, handlePageClicked]);


  if (getPagesCall.isLoading) {
    return (
      <PageContainer className="documentation-page">
        <AreaContainer>
          <Loader />
        </AreaContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer className="documentation-page page-no-scroll">
      <HorizontalResizer
        leftAreaRef={leftAreaRef}
        leftArea={
          <div className="documentation-page-tree-area" ref={leftAreaRef}>
            <ScrollLayout>
              <Tree
                isItemActive={(i) => i.pageId === currentPageId}
                items={pages}
                itemDefinition={itemDef}
                header={{
                  label: "Documentation",
                  actions: [
                    {
                      label: "Add",
                      icon: <PlusSVG />,
                      onClick: handleAddClicked
                    },
                  ],
                }}
              />
            </ScrollLayout>
          </div>
        }
        rightAreaRef={rightAreaRef}
        rightArea={
          <div ref={rightAreaRef} className="documentation-page-content-area">
            <PageContent defaultPage={defaultPageId} onSave={loadPages} />
          </div>
        } />
    </PageContainer>
  );
}
