import "./AddPagePopup.css";

import { useCallback, useMemo, useRef, useState } from "react";
import {
  useClosePopup,
  usePopup,
} from "../../../../lib/infrastructure/ui/UIServices";

import { AstroMarkCompiler } from "../../utils/AstroMarkCompiler";
import { Button } from "../../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import MdEditor from "react-markdown-editor-lite";
import { PageService } from "../../services/PageService";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { requiredValidator } from "../../../../lib/utils/ValidationChecks";
import { useFormControl } from "../../../../lib/components/form/Form";
import {
  useServiceCallPro,
} from "../../../../common/services/UseServiceCall";

export interface CreatePageRequestFromPopupDTO {
  title: string | undefined;
  content: string | undefined;
  spaceId: string;
}

export interface IAddPagePopupProps {
  onCompleted: (newPageId:number) => void;
  spaceId: string;
  pageId?: string | undefined;
}

const pagesSvc = new PageService();



export function AddPagePopup(props: IAddPagePopupProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const editorRef = useRef<MdEditor>(null);
  const [content, setContent] = useState<string>();

  const pageTitleFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
  });

  const addPageCall = useServiceCallPro(pagesSvc.createPage);

  console.log("Space ID", props.spaceId);
  console.log("Page ID", props.pageId);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  const isButtonDisabled = useMemo(() => {
    return addPageCall.isLoading;
  }, [addPageCall]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleEditorChange = useCallback(
    ({ html, text }: { html: any; text: any }) => {
      setContent(text);
    },
    [setContent]
  );

  const handleSavePageClicked = useCallback(() => {
    if (
      !pageTitleFormControl.validate() ||
      !props.spaceId ||
      pageTitleFormControl.value == undefined
    )
      return;



    addPageCall
      .invoke(pageTitleFormControl.value, content, props.spaceId, props.pageId)
      .then((res) => {
        props.onCompleted(res.pageId);
        openPopup(
          <SuccessPopup>
            <>You have created a Page successfully with title: {res.title}</>
          </SuccessPopup>
        );
      })
      .catch((err) => {
        openPopup(<ErrorPopup>{err.code}</ErrorPopup>);
      });
  }, [
    pageTitleFormControl,
    content,
    props.spaceId,
    openPopup,
    addPageCall,
    addPageCall.invoke,
  ]);

  /****************************
   * CSS & HTML
   *****************************/

  return (
    <PopupContainer className="add-page-popup">
      <div className="add-page-popup-header">
        <FormFieldTextInput  placeholder="Insert a title" formControl={pageTitleFormControl} />
        <div className="act-btns">
          <TextButton type="white" text={"Cancel"} onClick={closePopup} />
          <Button text="Save" type="primary" isDisabled={isButtonDisabled}
             onClick={handleSavePageClicked} />
        </div>
      </div>
      <LineSeparator />

      <Spacer px="15" mode="vertical" />
      <div className="page-content-renderer mk-editor">
        <MdEditor ref={editorRef} renderHTML={(text) => AstroMarkCompiler.compileToHtml(text)}  onChange={handleEditorChange} />
      </div>
    </PopupContainer>
  );
}
