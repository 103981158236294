import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from "react";

import { ConfigurationProvider } from "../../configuration/ConfigurationProvider";
import { HttpClient } from "../../http/HttpClient";
import { SplashScreen } from "../../../../common/components/splash-screen/SplashScreen";
import { UrlUtility } from "../../../utils/UrlUtility";
import { useIsAuthenticated } from "@azure/msal-react";

interface IRequireAuthProps {
  children: React.ReactNode;
}

export function RequireAuth(props: IRequireAuthProps) {
  const isAuthenticated = useIsAuthenticated();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const navigate = useNavigate();


  const handleGateResponse = useCallback((response: any) => {
    if(response){
      setIsValid(true);

    } else {
      setIsValid(false);
    }

  }, [setIsValid])

  const handleGateError = useCallback((error: any) => {
    if (error.code === "ERR_NETWORK") {
      setIsValid(false);
      navigate("/NetworkError");
      return;
    }
  }, [setIsValid, navigate])


  useEffect(() => {
    if (isAuthenticated) {
      HttpClient.sessionRequest({
        url: `${UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "/api/gate/verifysession")}`,
        method: "GET",
      })
        .then((res) => handleGateResponse(res.data))
        .catch((err) => handleGateError(err))
        .then((_) => setIsLoading(false));
    }
  }, [isAuthenticated]);



  if (!isAuthenticated) {
    return <Navigate to={`/identity/login?url=${location.pathname}`} />;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isValid) {
    return <Navigate to={`/identity/sessionExpired?url=${location.pathname}`}/>;
  }


  return <>{props.children}</>;
}
